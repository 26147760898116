<template>
  <div
    class="modal fade"
    id="vehicle_upload_modal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="vehicleUploadModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Sign Up</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <Form
            @submit="onSignupSubmit"
            :validation-schema="signup"
            class="form-vertical"
            role="form"
            id="vehicles_upload_form"
          >
            <div class="d-flex">
              <!-- <div class="h-24"> -->
              <div class="col-6">
                <div class="">
                  <label class="font-weight-bold text-dark"
                    >First Name
                    <!-- <span class="text-danger">(Required)</span> -->
                    </label
                  >
                </div>
                <div class="mt-2">
                  <Field
                    name="first_name"
                    class="px-2 py-2 w-100 rounded bg-light text-dark border border-bottom-1"
                    type="text"
                  />
                  <ErrorMessage
                    name="first_name"
                    class="text-danger py-3 text-sm"
                  />
                </div>
                <!-- </div> -->
              </div>
              <!-- <div class="h-24"> -->
              <div class="col-6">
                <div class="">
                  <label class="font-weight-bold"
                    >Last Name
                    <!-- <span class="text-danger">(Required)</span> -->
                    </label
                  >
                </div>
                <div class="mt-2">
                  <Field
                    name="last_name"
                    class="px-2 py-2 w-100 rounded bg-light text-dark border border-bottom"
                    type="text"
                  />
                  <ErrorMessage
                    name="last_name"
                    class="text-danger py-3 text-sm"
                  />
                </div>
                <!-- </div> -->
              </div>
            </div>
            <div class="d-flex mt-2">
              <!-- <div class="h-24"> -->
              <div class="col-6">
                <div class="">
                  <label class="font-weight-bold"
                    >Email
                     <!-- <span class="text-danger">(Required)</span> -->
                    </label
                  >
                </div>
                <div class="mt-2">
                  <Field
                    name="email"
                    class="px-2 py-2 w-100 rounded bg-light text-dark border border-bottom"
                    type="email"
                  />
                  <ErrorMessage name="email" class="text-danger py-3 text-sm" />
                  <!-- </div> -->
                </div>
              </div>
              <!-- <div class="h-24"> -->
              <div class="col-6">
                <div class="">
                  <label class="font-weight-bold">SCAC</label>
                </div>
                <div class="mt-2">
                  <Field
                    name="scac"
                    class="px-2 py-2 w-100 rounded bg-light text-dark border border-bottom"
                    type="text"
                  />
                  <!-- <ErrorMessage name="client_asset_upload" class="text-danger py-3 text-sm" /> -->
                </div>
                <!-- </div> -->
              </div>
            </div>

            <div class="mt-2">
              <div class="col">
                <div class="">
                  <label class="font-weight-bold">Company Name</label>
                </div>
                <div class="mt-2">
                  <Field
                    name="company"
                    class="px-2 py-2 w-100 rounded bg-light w-100 text-dark border border-bottom"
                    type="text"
                  />
                </div>
              </div>
            </div>
            <div class="d-flex mt-2">
              <!-- <div class="h-24"> -->
              <div class="col-6">
                <div class="">
                  <label class="font-weight-bold"
                    >Fleet size
                    <!-- <span class="text-danger">(Required)</span> -->
                    </label
                  >
                </div>
                <div class="mt-2">
                  <Field
                    name="fleet_size"
                    class="px-2 py-2 w-100 rounded bg-light text-dark border border-bottom"
                    type="number"
                  />
                  <ErrorMessage
                    name="fleet_size"
                    class="text-danger py-3 text-sm"
                  />
                </div>
              </div>
              <!-- </div> -->
              <!-- <div class="h-24"> -->
              <div class="col-6">
                <div class="">
                  <label class="font-weight-bold"
                    >Phone 
                    <!-- <span class="text-danger">(Required)</span> -->
                    </label
                  >
                </div>
                <div class="mt-2">
                  <Field
                    name="phone"
                    class="px-2 py-2 w-100 rounded bg-light text-dark border border-bottom"
                    type="text"
                  />
                  <ErrorMessage name="phone" class="text-danger py-3 text-sm" />
                </div>
              </div>
              <!-- </div> -->
            </div>
            
            <div class="mt-2 mb-4">
              <div class="col">
                <div class="">
                  <label class="font-weight-bold"
                    >Street Address
                    <!-- <span class="text-danger">(Required)</span> -->
                    </label
                  >
                </div>
                <div class="mt-2">
                  
                  <input
                    id="address"
                    name="address"
                    placeholder="address"
                    class="px-2 py-2 w-100 rounded bg-light text-dark border border-bottom"
                    type="text" ref="address"
                    v-model="address"
                    autocomplete="off"
                  />
                  
                </div>
              </div>
            </div>
              
            <div class="mt-2 mb-4">
              <div class="col">
                <div class="">
                  <label class="font-weight-bold"
                    >Street Name
                    <span class="text-danger">(Required)</span>
                    </label
                  >
                </div>
                <div class="mt-2">
                  
                  <input
                    id="shipping_street"
                    name="shipping_street"
                    placeholder="shipping_street"
                    class="px-2 py-2 w-100 rounded bg-light text-dark border border-bottom"
                    type="text" ref="shipping_street"
                    v-model="shipping_street"
                  />
                  
                </div>
              </div>
            </div>
            <div class="d-flex mt-2 mb-4">
              <div class="col-4">
                <div class="">
                  <label class="font-weight-bold"
                    >City <span class="text-danger">(Required)</span></label
                  >
                </div>
                <div class="mt-2">
                  <!-- <vue-google-autocomplete
                ref="address"
                id="map"
                classname="form-control"
                placeholder="Please type your address"
                v-on:placechanged="getAddressData"
                country="us"
              >
              </vue-google-autocomplete> -->
                  <input
                    id="shipping_city"
                    name="shipping_city"
                    placeholder="shipping_city"
                    class="px-2 py-2 w-100 rounded bg-light text-dark border border-bottom"
                    type="text" ref="shipping_city"
                    v-model="shipping_city"
                  />
                </div>
              </div>
              <div class="col-4">
                <div class="">
                  <label class="font-weight-bold"
                    >State <span class="text-danger">(Required)</span></label
                  >
                </div>
                <div class="mt-2">
                  <input
                    id="shipping_state"
                    name="shipping_state"
                    placeholder="shipping_state"
                    class="px-2 py-2 w-100 rounded bg-light text-dark border border-bottom"
                    type="text" ref="shipping_state"
                    v-model="shipping_state"
                  />
                </div>
              </div>
              <div class="col-4">
                <div class="">
                  <label class="font-weight-bold"
                    >Zip Code <span class="text-danger">(Required)</span></label
                  >
                </div>
                <div class="mt-2">
                  <input
                    id="shipping_zip_code"
                    name="shipping_zip_code"
                    placeholder="shipping_zip_code"
                    class="px-2 py-2 w-100 rounded bg-light text-dark border border-bottom"
                    type="text" ref="shipping_zip_code"
                    v-model="shipping_zip_code"
                  />
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-danger btn-outline btn-xs"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                type="submit"
                id="submit_btn"
                class="btn btn-primary btn-outline btn-xs"
              >
                Submit
              </button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  </div>
  <main>
    <div v-if="loading" id="preloader">
      <div class="preloader">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
    <div class="container-fluid 100vh">
      <div class="row">
        <div class="col-sm-4 login-section-wrapper">
          <div class="brand-wrapper">
            <img
              src="@/assets/logo1.png"
              class="logo"
              alt="https://www.innovativetoll.com"
            />
          </div>
          <div class="login-wrapper my-auto">
            <h2 class="login-title">Log in</h2>
            <p class="text-gray-400"><em>Log-in to get started</em></p>
            <Form
              @submit="handleLogin"
              :validation-schema="schema"
              class="user"
            >
              <div class="form-group">
                <Field
                  name="email"
                  type="text"
                  class="form-control form-control-user"
                  placeholder="Enter Email ..."
                />
                <ErrorMessage name="email" class="text-danger p-3" />
              </div>
              <div class="form-group">
                <Field
                  name="password"
                  type="password"
                  class="form-control form-control-user"
                  placeholder="Password"
                />
                <ErrorMessage name="password" class="text-danger p-3" />
              </div>
              <div class="form-group">
                <div v-if="message" class="alert alert-danger" role="alert">
                  {{ message }}
                </div>
              </div>
              <button
                class="btn btn-info col-sm-8 rounded-pill"
                :disabled="loading"
              >
                <span
                  v-show="loading"
                  class="spinner-border spinner-border-sm pr-2"
                ></span>
                <span>Log In</span>
              </button>
            </Form>
            <br />
            <router-link class="nav-link mb-0 text-sm mx-auto" to="/reset"
              >Forgot password?</router-link
            >
            <p class="login-wrapper-footer-text">
              Don't have an account?
              <button
                data-toggle="modal"
                data-target="#vehicle_upload_modal"
                class="text-primary underline bg-white border border-white special"
              >
                <u>Register here</u>
              </button>
            </p>
            <br />
            <div class="container">
              <div class="copyright ml-auto">
                {{ new Date().getFullYear() }}
                <a href="https://www.innovativetoll.com"
                  >Innovative Toll Solutions</a
                >
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-sm-8 px-0 d-none d-sm-block"
          style="
            background-image: url(https://crm.innovativetoll.com/assets/images/login_bg.png);
            display: block;
            background-size: cover;
            background-repeat: no-repeat;
            overflow: auto;
          "
        >
          <div class="maintxt">
            <h1 class=""><b>INNOVATIVE TOLL</b></h1>
            <span class="overlay-text"
              >Form a relationship with a great toll management solution
              provider <br />
              and set yourself for success in the changing Tolling space
            </span>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import AuthService from "@/services/auth.service";
import { Loader } from "@googlemaps/js-api-loader"
import VueGoogleAutocomplete from "vue-google-autocomplete";
import * as yup from "yup";

export default {
  name: "SignIn",
  components: {
    Form,
    Field,
    ErrorMessage,
    VueGoogleAutocomplete,
  },
  data() {
    const schema = yup.object().shape({
      email: yup
        .string()
        .required("Email is required!")
        .email("Email is invalid!")
        .max(50, "Must be maximum 50 characters!"),
      password: yup.string().required("Password is required!"),
    });
    const signup = yup.object().shape({
      email: yup
        .string()
        .required("Email is required!")
        .email("Email is invalid!")
        .max(50, "Must be maximum 50 characters!"),
      // shipping_street: yup
      //   .string("Shipping street is required!")
      //   .required("Shipping street is required!"),
      phone: yup
        .string("phone is required!")
        .required("phone Number is required!"),
      first_name: yup
        .string("First name is required!")
        .required("First name is required!"),
      last_name: yup
        .string("Last name is required!")
        .required("Last name is required!"),
      fleet_size: yup
        .number("Must be a number type") // Validates for numerical value
        .positive("Must be a positive value") // Validates against negative values
        .required("Please enter a fleet size.") // Sets it as a compulsory field
        .min(1, "Fleet size must be greater than or equal to 1"), // Sets a minimum value});
      address: yup
        .string("Shipping address is required!")
        .required("Shipping address is required!"),
      // shipping_state: yup
      //   .string("Shipping state is required!")
      //   .required("Shipping state is required!"),
      // shipping_zip_code: yup
      //   .number("Shipping zip_code is required!")
      //   .required("Shipping zip_code is required!"),
    });

    return {
      loading: false,
      showmodel: false,
      message: "",
      schema,
      signup,
      title: "",
      shipping_city: "",
      shipping_state: "",
      shipping_street: "",
      shipping_zip_code: "",
      address: "",
      // showMessage: false,
    };
  },
  
  methods: {
    async handleLogin(user) {
      this.loading = true;
      this.$store
        .dispatch("auth/login", user)
        .then((response) => {
          this.$router.push("/dashboard");
          this.loading = false;
        })
        .catch((error) => {
          this.$swal({
            title: error.response.data.error,
            position: "top-end",
            icon: "warning",
            showConfirmButton: false,
            timer: 6000,
          });
          this.loading = false;
          // this.showMessage = true;
        });
    },
    onSignupSubmit(user) {
      
      user["shipping_state"] = this.shipping_state;
      user["shipping_city"] = this.shipping_city;
      user["shipping_street"] = this.shipping_street;
      user["shipping_zip_code"] = this.shipping_zip_code;
      user["address"] = this.address;
      console.log(user);
      AuthService.signup(user)
        .then((response) => {
          if (response.data.data) {
            this.$swal({
              title:
                "Thank you for submitting your details. Our team will get back to you within 24 - 48 hours.",
              position: "top-end",
              icon: "success",
              showConfirmButton: false,
              timer: 5000,
            });
            $('#vehicle_upload_modal').modal('hide');
            this.showmodel = false;
          } else {
            this.$swal({
              title: response.data.error,
              position: "top-end",
              icon: "warning",
              showConfirmButton: false,
              timer: 3000,
            });
            this.showmodel = false;
          }
        })
        .catch((err) => console.log(err.message));
      // send post request to api
      // close modal
    },
    tougleModal() {
      this.titleModal = "Add User";
    },
   

  },
  mounted() {
    var pacContainerInitialized = false;
    $("#address").keypress(function() {
      if (!pacContainerInitialized) {
        $(".pac-container").css("z-index", "9999");
        pacContainerInitialized = true;
      }
    });

      var address = document.getElementById("address");
        const autocomplete = new google.maps.places.Autocomplete(address, {
        componentRestrictions: { country: ["us", "ca"] },
        fields: ["address_components"],
        types: ["address"],
      });

    autocomplete.addListener("place_changed", () => {
      // console.log(autocomplete.getPlace());
      let place = autocomplete.getPlace();
      this.address = "";
      this.shipping_state = "";
      this.shipping_city = "";
      this.shipping_street = "";
      this.shipping_zip_code = "";
      let postcode = "";
      let address = "";
      let city = "";
      let street = "";
      let state = "";
      console.log(place);

      // this.address = place.address_components;
      console.log(this.address);
      // var code = address[address.length -1].long_name;
      for (const component of place.address_components) {
        // @ts-ignore remove once typings fixed
        const componentType = component.types[0];

        switch (componentType) {
          case "street_number": {
            street = `${component.long_name} ${street}`;
            break;
          }

          case "route": {
            street += component.short_name;
            break;
          }

          case "postal_code": {
            postcode = `${component.long_name}${postcode}`;
            break;
          }
          case "country": {
            address = `${street}  ${city}, ${state}`;
            break;
          }

          case "locality":
          city = component.long_name;
            break;
          case "administrative_area_level_1": {
            state = component.short_name;
            break;
          }
          case "route":
          state = component.long_name;
            break;
        }
      }

      this.address = address;
      this.shipping_state = state;
      this.shipping_city = city;
      this.shipping_zip_code = postcode;
      this.shipping_street = street;
    } )
},
};

</script>
<style scoped src="../../assets/css/Main.css">
.special {
  cursor: crosshair;
}
.pac-container {
    z-index: 1051 !important;
}
</style>
