<template>
  <div class="wrapper overlay-sidebar">
    <div>hello world</div>
  </div>
</template>
<script>
export default {
  name: "hub",
  data() {
    // var PERMITTED_DOMAIN = "http://localhost:8081";
    // var PERMITTED_DOMAIN = "https://admin.test.innovativetoll.com";
    var PERMITTED_DOMAIN = "https://admin.innovativetoll.com"; // Production
    window.addEventListener("message", function (event) {
      console.log("hello this is hub.vue");
      console.log(event.origin);
      //   console.log(event);
      if (event.origin === PERMITTED_DOMAIN) {
        // var msg = JSON.parse(event.data);
        var msg = event.data;
        console.log(event.data);
        var msgKey = Object.keys(msg)[0];
        console.log(msgKey);
        // const value
        if (event.data) {
          if (msgKey === "contact") {
            localStorage.setItem(msgKey, event.data.contact);
          } else if (msgKey === "user") {
            localStorage.setItem(msgKey, event.data.user);
          } else if (msgKey === "hasAccess") {
            localStorage.setItem(msgKey, event.data.hasAccess);
          } else if (msgKey === "client_sub_dept") {
            localStorage.setItem(msgKey, event.data.client_sub_dept);
          } else if (msgKey === "client_dept") {
            localStorage.setItem(msgKey, event.data.client_dept);
          }
        } else {
          localStorage.removeItem("localstorage");
        }
      }
    });
    return {};
  },

  computed: {},

  methods: {},
  //   created() {
  //     this.filters = this.axiosParams
  // 		this.loaddata()
  //   },
};
</script>

<style type="text/css">
table {
  white-space: nowrap;
  width: 100% !important;
  padding: 1px;
  border-collapse: collapse !important;
}
th {
  background-color: #d2e8fa !important;
  color: #5a6268 !important;
}
</style>
