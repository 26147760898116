import api from "./api";

class UtilsService {
  /* innovative */
  getStates() {
    return api.get("/states");
  }
  getClient(filter) {
    return api.get("/clients", { params: filter });
  }
  getVehicleStatus() {
    return api.get("/vehicle-status");
  }
  getaxles() {
    return api.get(`/axles`);
  }
  getTagTypes() {
    return api.get("/tag-types");
  }
  getDepartments(client) {
    return api.get(`/departments?client_id=${client}`);
  }
  getSubDepartments({ dept }) {
    return api.get("/sub-departments", null, { dept });
  }
  getSubsidiaries(client) {
    return api.get(`/subsidiaries?client_id=${client}`);
  }
  getAuthUser() {
    return api.get(`/active-user`);
  }
  //modules
  getModules() {
    return api.get("/modules");
  }
  async getOverviewDept(client) {
    return await api.get(`/default-department/${client}`);
  }

  getuserdetails(userId) {
    return api.get(`/contact-user/${userId}`);
  }
  getclientdetails(clientId) {
    return api.get(`/clients/${clientId}`);
  }
  getClientDefaultUser(clientId) {
    return api.get(`/default-user/${clientId}`);
  }
  getDept(deptId) {
    return api.get(`/departments/${deptId}`);
  }
  sendOtp(data) {
    return api.post("/send_email_otp", data);
  }
  resetPassword(data) {
    return api.post("/updatepassword", data);
  }
  getRegion() {
    return api.get(`/travel-regions`);
  }
  
  tollTransponders(param) {
    return api.get(`/fetch-toll-transponder${param}`);
  }
  getVehicle(vehicle_id) {
    return api.get(`/toll-transponder/${vehicle_id}`);
 }
  editvehicle(vehicleID, data) {
    return api.post(`/toll-transponder/${vehicleID}?_method=put`,data);
  }
  addvehicle(data) {
    return api.post(`/toll-transponder`,data);
  }
  deletevehicle(vehicleID) {
    return api.delete(`/toll-transponder/${vehicleID}`);
  }
  TransactionsPike() {
    return api.get(`/transactions`);
  }
  GetSubsidiary() {
    return api.get(`/business-units`);
  }
  ReassignTransponder(vehicleID, dataE) {
    return api.post(`/reassign-transponder/${vehicleID}?_method=put`,dataE);
  }
  GetLogs() {
    return api.get(`/client-logs`);
  }
}

export default new UtilsService();
